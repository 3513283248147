@font-face
    font-family: "LostinWildRegular"
    src: url("./assets/kit/fonts/Lost in Wild Regular.ttf") format("truetype")
    font-weight: bold
@font-face
    font-family: "KaushanScript"
    src: url("./assets/kit/fonts/KaushanScript-Regular.ttf") format("truetype")
    font-weight: bold
@font-face
    font-family: "Norican"
    src: url("./assets/kit/fonts/Norican-Regular.ttf") format("truetype")
    font-weight: bold
@font-face
    font-family: "Qwigley"
    src: url("./assets/kit/fonts/Qwigley-Regular.ttf") format("truetype")
    font-weight: bold
@font-face
    font-family: "Arizonia"
    src: url("./assets/kit/fonts/Arizonia-Regular.ttf") format("truetype")
    font-weight: bold

.css-12e347i-JoyCardCover-root [data-first-child], .css-lisf7p-JoyAspectRatio-root, .css-1fu2e3p-MuiPaper-root-MuiDialog-paper
    border-radius: 0 !important